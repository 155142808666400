<template>
  <div class="container global-list-main-container">
    <div class="upper-global-hint-inner">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns-inner">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="table-header">
      Cantidad de cambios: <b>{{ changesInformation.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      :data="changesInformation"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      scrollable
      ref="ChangesTable"
      :checked-rows.sync="checkedChanges"
      paginated
      :current-page="currentPage"
      :per-page="perPage"
      :selected.sync="selected"
      detail-key="_id"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="desc"
      checkbox-position="left"
      default-sort="COSTO"
    >
      <template #empty>
        <div class="has-text-centered">No hay cambios</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          searchable
          sortable
          :visible="column.display"
          v-bind="column"
          :key="column.field"
          :label="column.label"
          :custom-sort="column.customSort"
        >
          <span v-if="!checkIfValid(props.row[column.field])">{{
            "Sin información"
          }}</span>
          <span> {{ props.row[column.field] }}</span>
        </b-table-column>
      </template>

      <template slot="detail">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64"></p>
          </figure>
          <div class="media-content">
            <p>No hay información que mostrar</p>
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";

const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

export default {
  name: "ProvidersArticlesTable",
  props: ["information"],
  components: {},
  directives: { cleave },
  data() {
    //warehouses
    return {
      perPage: 50,
      checkedChanges: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "COSTO",
          label: "Costo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formatedDate",
          label: "Fecha",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDate,
        },
      ],
      changesInformation: this.information.CAMBIOS,
      masks: Masks,
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    for (const [
      singleChnageIndex,
      singleChange,
    ] of this.changesInformation.entries()) {
      this.information.CAMBIOS[singleChnageIndex].formatedDate =
        this.formatedDate(singleChange.FECHA);
    }
  },
  methods: {
    formatedDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
  },
  watch: {
    /**
     * @desc Select default opened details, to open a specific warehouse
     */
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
